import React from 'react'
import Layout from "../../components/Layout"
import StepCart from '../../components/StepCart'
import image from "../../images/ShoppingCart/check.png"

export default function CartSuccess(props) {
    const { path } = props
    return ( 
        <Layout>
            <div className="CartSuccess">
                <div className="CartSuccess-container">
                    <StepCart path={path} />
                    <div className="CartSuccess-content">
                        <div className="CartSuccess-image" style={{ backgroundImage: `url(${image})` }}/>
                        <span className="CartSuccess-title">¡Gracias por tu compra!</span>
                        <span className="CartSuccess-text">Tu pedido está confirmado, en breve recibirás un correo electrónico de confirmación con tu nº de pedido</span>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
